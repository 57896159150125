<template>
  <a-modal
    v-model:visible="visible"
    title="更改密码"
    :footer="false"
    @cancel="handleCancel"
  >
    <div
      id="updateUserPassword"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 35vh;
      "
    >
      <a-form
        :model="form"
        :style="{ width: '450px' }"
        label-align="left"
        auto-label-width
        @submit="handleSubmit"
      >
        <a-form-item label="&ensp;&nbsp;用户账号：">
          <a-input v-model="loginUserStore.loginUser.userAccount" disabled />
        </a-form-item>
        <a-form-item label="&ensp;&nbsp;用户昵称：">
          <a-input
            v-model="loginUserStore.loginUser.userName"
            placeholder="请输入用户名"
            disabled
          />
        </a-form-item>
        <a-form-item
          field="oldPassword"
          label="请输入旧密码："
          :rules="[{ required: true, message: '此项为必填' }]"
        >
          <a-input-password v-model="form.oldPassword" :maxlength="18" />
        </a-form-item>
        <a-form-item
          field="newPassword"
          tooltip="密码不小于8位"
          label="请输入新密码："
          :rules="[{ required: true, message: '此项为必填' }]"
        >
          <a-input-password v-model="form.newPassword" :maxlength="18" />
        </a-form-item>
        <a-form-item
          field="checkPassword"
          tooltip="密码不小于8位"
          label="请确认新密码："
          :rules="[{ required: true, message: '此项为必填' }]"
        >
          <a-input-password
            v-model="form.checkPassword"
            :maxlength="18"
            :error="passwordMismatch"
          />
        </a-form-item>
        <!-- 显示错误提示 -->
        <a-space v-if="passwordMismatch" style="color: #ff5454; margin: 0 auto"
          >新密码和确认密码不一致
        </a-space>
        <a-button
          type="primary"
          html-type="submit"
          style="width: 120px; margin: 0 auto"
          :disabled="passwordMismatch"
        >
          提交
        </a-button>
      </a-form>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, defineExpose, computed } from "vue";
import API from "@/api";
import { updatePassword } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import router from "@/router";

const loginUserStore = useLoginUserStore();
const isLoading = ref(false);
const form = ref({
  newPassword: "",
  oldPassword: "",
  checkPassword: "",
} as API.UserUpdatePasswordRequest);

// 是否可见
const visible = ref(false);

// 打开弹窗
const openModal = () => {
  visible.value = true;
};

// 暴露函数给父组件
defineExpose({
  openModal,
});

// 关闭弹窗
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交修改密码请求
 */
const handleSubmit = async () => {
  isLoading.value = true;

  // 检查输入是否完整
  if (
    !form.value.oldPassword ||
    !form.value.newPassword ||
    !form.value.checkPassword
  ) {
    message.error("请输入完整信息！");
    isLoading.value = false;
    return;
  }

  // 检查新密码和确认密码是否一致
  if (passwordMismatch.value) {
    message.error("新密码和确认密码不一致！");
    isLoading.value = false;
    return;
  }

  const res = await updatePassword(form.value);
  if (res.data.code === 0) {
    message.success("修改成功！请重新登录...");
    loginUserStore.clearLoginState();
    // 延迟跳转至主页并刷新页面
    setTimeout(() => {
      router.replace("/").then(() => {
        window.location.reload();
      });
    }, 800);
  } else {
    message.error("修改失败，" + (res.data.message || "未知错误"));
  }

  isLoading.value = false;
};

/**
 * 计算属性，用于检测新密码和确认密码是否一致
 */
const passwordMismatch = computed(() => {
  return (
    form.value.newPassword !== form.value.checkPassword &&
    form.value.checkPassword !== ""
  );
});
</script>

<style scoped></style>
