<template>
  <a-modal
    v-model:visible="visible"
    title="个人中心"
    :footer="false"
    @cancel="handleCancel"
  >
    <div
      id="userCenter"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 36vh;
      "
    >
      <a-form
        :model="form"
        :style="{ width: '450px' }"
        label-align="left"
        auto-label-width
        @submit="handleSubmit"
      >
        <a-form-item field="userName" label="用户账号：">
          <a-input
            v-model="loginUserStore.loginUser.userAccount"
            placeholder="请输入用户名"
            disabled
          />
        </a-form-item>
        <a-form-item field="userName" label="用户昵称：">
          <a-input v-model="form.userName" placeholder="请输入用户名" />
        </a-form-item>
        <a-form-item field="userAvatar" label="用户头像：">
          <PictureUploader
            v-model="form.userAvatar"
            @input="updateUserAvatar"
            :value="form.userAvatar"
          />
        </a-form-item>
        <a-form-item field="userProfile" label="用户简介：">
          <a-input v-model="form.userProfile" placeholder="请输入用户简介" />
        </a-form-item>
        <a-form-item field="userRole" label="用户身份：" disabled>
          <a-input v-model="loginUserStore.loginUser.userRole" />
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          style="width: 120px; margin: 0 auto"
          :disabled="isLoading"
        >
          提交
        </a-button>
      </a-form>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, defineExpose, watchEffect } from "vue";
import API from "@/api";
import {
  getUserVoById,
  updateMyUser,
} from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import PictureUploader from "@/components/PictureUploader.vue";
import router from "@/router";

const loginUserStore = useLoginUserStore();
const isLoading = ref(false);
const form = ref({
  userName: "",
  userAvatar: "",
  userProfile: "",
} as API.UserUpdateMyRequest);

/**
 * 加载数据
 */
const loadData = async () => {
  if (!loginUserStore.loginUser.id) {
    return;
  }
  const res = await getUserVoById({
    id: loginUserStore.loginUser.id as number,
  });
  if (res.data.code === 0 && res.data.data) {
    form.value = res.data.data;
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};
// 用于接收uploader组件传来的图片地址
const updateUserAvatar = (url: string) => {
  form.value.userAvatar = url; // 更新表单中的 appIcon 属性
};
// 获取旧数据
watchEffect(() => {
  loadData();
});

// 是否可见
const visible = ref(false);

// 打开弹窗
const openModal = () => {
  visible.value = true;
};

// 暴露函数给父组件
defineExpose({
  openModal,
});

// 关闭弹窗
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async () => {
  isLoading.value = true;
  // 校验输入
  if (form.value.userName === "") {
    message.error("用户名不能为空！");
    isLoading.value = false;
    return;
  }
  // 发起更新请求
  const res = await updateMyUser(form.value);
  if (res.data.code === 0) {
    message.success("修改成功！准备刷新...");
    // 同步更新 Pinia 和 localStorage
    await loginUserStore.fetchLoginUser();
    // 延迟跳转至主页并刷新页面
    setTimeout(() => {
      router.replace("/").then(() => {
        window.location.reload();
      });
    }, 300);
    visible.value = false;
  } else {
    message.error("修改失败，" + (res.data.message || "未知错误"));
  }

  isLoading.value = false;
};
</script>

<style scoped></style>
