import axios from "axios";
import { useLoginUserStore } from "@/store/userStore";

// 是否是开发环境
export const isDev = process.env.NODE_ENV === "development";

const myAxios = axios.create({
  baseURL: isDev
    ? "http://localhost:8080"
    : "https://springboot-3oa6-140310-4-1331347804.sh.run.tcloudbase.com",
  timeout: 60000,
  withCredentials: true,
});

// 请求拦截器：自动附加Token到请求头
myAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token; // 格式需与后端约定（如Bearer）
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
myAxios.interceptors.response.use(
  function (response) {
    // 如果响应头中包含 Authorization（JWT Token）
    const token = response.headers['authorization'] || response.headers['Authorization'];
    if (token) {
      // 将 Token 存储到 localStorage
      localStorage.setItem("token", token);
    }
    // 响应成功逻辑，可根据需要处理其他返回值
    return response;
  },
  (error) => {
    const loginUserStore = useLoginUserStore();
    if (error.response?.status === 415) {
      // 仅在Token存在时执行跳转（避免其他接口的401误触发）
      if (localStorage.getItem("token") || localStorage.getItem("")) {
        console.error("Token无效或已过期，请重新登录");
        loginUserStore.clearLoginState();
        window.location.href = `/user/login?redirect=${encodeURIComponent(window.location.pathname)}`;
      }
    }
    return Promise.reject(error);
  }
);
export default myAxios;
