import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "addQuestionPage" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-start"} }

import { ref, watchEffect } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import {
  addTopic,
  updateTopic,
  listTopicByPage,
  deleteTopic,
} from "@/api/topicController";
import message from "@arco-design/web-vue/es/message";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";

interface Props {
  appId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddQuestionPage',
  props: {
    appId: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const isLoading = ref(true);

// 题目内容结构（题目列表）
const topicDTO = ref<API.TopicDTO[]>([
  {
    topicId: 0,
    topicContent: {
      title: "",
      options: [
        {
          key: "",
          value: "",
          result: "",
          score: 0,
        },
      ],
    },
    difficulty: 0,
  },
]);

/**
 * 添加题目
 * @param index
 */
const addTopics = (index: number) => {
  topicDTO.value.splice(index, 0, {
    topicContent: {
      title: "",
      options: [
        {
          key: "",
          value: "",
          result: "",
          score: 0,
        },
      ],
    },
    difficulty: 0,
  });
};

// 存储被删除的题目 ID
const deletedTopicIds = ref<number[]>([]);

/**
 * 删除题目
 * @param index
 */
const deleteTopics = (index: number) => {
  const deletedTopic = topicDTO.value[index];
  if (deletedTopic.topicId) {
    deletedTopicIds.value.push(deletedTopic.topicId);
  }
  topicDTO.value.splice(index, 1);
};

/**
 * 添加题目选项
 * @param topic
 * @param index
 */
const addTopicOption = (topic: API.TopicContent, index: number) => {
  if (!topic.options) {
    topic.options = [];
  }
  topic.options.splice(index, 0, {
    key: "",
    value: "",
    result: "",
    score: 0,
  });
};

/**
 * 清空所有题目
 */
const clearAllTopics = () => {
  deletedTopicIds.value.push(
    ...topicDTO.value
      .filter((topic) => topic.topicId)
      .map((topic) => topic.topicId as number)
  );
  topicDTO.value = [];
  console.log("清空所有题目");
};

/**
 * 删除题目选项
 * @param topic
 * @param index
 */
const deleteTopicOption = (topic: API.TopicContent, index: number) => {
  if (!topic.options) {
    topic.options = [];
  }
  topic.options.splice(index, 1);
};

const oldTopic = ref<API.Topic>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  const res = await listTopicByPage({
    appId: props.appId as any,
    current: 1,
    pageSize: 100,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    // 清空当前数据
    topicDTO.value = [];
    // 遍历所有记录
    res.data.data.records.forEach((record: API.Topic) => {
      try {
        // 解析 topicContent 字符串为对象
        const parsedTopicContent = JSON.parse(record.topicContent ?? "{}");
        // 添加到 topicDTO
        topicDTO.value.push({
          topicId: record.topicId,
          topicContent: {
            title: parsedTopicContent.title ?? "",
            options: parsedTopicContent.options ?? [],
          },
          difficulty: record.difficulty,
        });
        // 更新 oldTopic
        if (!oldTopic.value) {
          oldTopic.value = record;
        }
      } catch (error) {
        console.error("解析 topicContent 失败:", error);
        // 提供默认值
        topicDTO.value.push({
          topicContent: {
            title: "",
            options: [],
          },
        });
      }
    });
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

// 获取旧数据
watchEffect(() => {
  loadData();
});

const buttonDisable = ref(false);
/**
 * 提交
 */
/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId || !topicDTO.value) {
    return;
  }
  buttonDisable.value = true;

  let updateResult: any = null;
  let createResult: any = null;
  let deleteResult: any = null;

  try {
    // 处理删除题目
    if (deletedTopicIds.value.length > 0) {
      deleteResult = await deleteTopic({
        appId: props.appId as any,
        topicIds: deletedTopicIds.value,
      });
      if (deleteResult.data.code !== 0) {
        message.error(deleteResult.data.message);
      } else {
        message.success("删除题目成功");
        deletedTopicIds.value = []; // 清空删除队列
      }
    }

    // 处理更新和新增
    const topicsToUpdate = topicDTO.value.filter(
      (topic) => topic.topicId && topic.topicId !== 0
    );
    const topicsToCreate = topicDTO.value.filter(
      (topic) => !topic.topicId || topic.topicId === 0
    );

    if (topicsToUpdate.length > 0) {
      updateResult = await updateTopic({
        appId: props.appId as any,
        topics: topicsToUpdate,
      });
      if (updateResult.data.code !== 0) {
        message.error(updateResult.data.message);
      }
    }

    if (topicsToCreate.length > 0) {
      createResult = await addTopic({
        appId: props.appId as any,
        topics: topicsToCreate,
      });
      if (createResult.data.code !== 0) {
        message.error(createResult.data.message);
      }
    }

    // 如果所有操作成功，跳转
    message.success("操作成功，即将跳转到应用详情页");
    setTimeout(() => {
      router.push(`/app/detail/${props.appId}`);
    }, 800);
  } catch (error) {
    message.error("操作失败，" + (error as Error).message);
  } finally {
    buttonDisable.value = false;
  }
};

/**
 * AI 生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.TopicDTO[]) => {
  message.success(`AI 生成题目成功，生成 ${result.length} 道题目`);
  topicDTO.value = [...topicDTO.value, ...result];
};

/**
 * AI 生成题目成功后执行（SSE）
 */
const onAiGenerateSuccessSSE = (result: API.TopicContent) => {
  // 将返回的 TopicContent 对象包装成一个完整的 TopicDTO 对象
  const newTopic: API.TopicDTO = {
    topicId: 0, // 假设新生成的题目没有 ID
    topicContent: result,
    difficulty: 0, // 默认难度
  };
  // 将新题目添加到 topicDTO 中
  topicDTO.value = [...topicDTO.value, newTopic];
};

/**
 * SSE 开始生成
 * @param event
 */
const onSSEStart = (event: any) => {
  message.success("开始生成");
};

/**
 * SSE 生成完毕
 * @param event
 */
const onSSEClose = (event: any) => {
  message.success("生成完毕");
};

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_collapse_item = _resolveComponent("a-collapse-item")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("h2", { style: {"margin-bottom":"32px","text-align":"center"} }, "设置题目", -1)),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_form, {
          key: 1,
          model: topicDTO.value,
          style: { width: '900px' },
          "label-align": "left",
          "auto-label-width": "",
          onSubmit: handleSubmit
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_space, { size: "large" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "题目列表" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_space, { size: "large" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            status: "success",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (addTopics(topicDTO.value.length)))
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" 底部添加题目 ")
                            ])),
                            _: 1
                          }),
                          _createVNode(AiGenerateQuestionDrawer, {
                            appId: _ctx.appId,
                            onSuccess: onAiGenerateSuccess,
                            onSSESuccess: onAiGenerateSuccessSSE,
                            onSSEClose: onSSEClose,
                            onSSEStart: onSSEStart
                          }, null, 8, ["appId"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            "html-type": "submit",
                            style: {"width":"120px"},
                            disabled: buttonDisable.value
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" 提交 ")
                            ])),
                            _: 1
                          }, 8, ["disabled"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            status: "danger",
                            style: {"width":"120px"},
                            onClick: clearAllTopics
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" 清空所有题目 ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "应用 id" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.appId), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_a_collapse, {
              "default-active-key": ['0'],
              "destroy-on-hide": ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topicDTO.value, (topic, index) => {
                  return (_openBlock(), _createBlock(_component_a_collapse_item, { key: index }, {
                    header: _withCtx(() => [
                      _createVNode(_component_a_space, { size: "small" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(index + 1) + ": ", 1),
                          _createVNode(_component_a_input, {
                            modelValue: topic.topicContent.title,
                            "onUpdate:modelValue": ($event: any) => ((topic.topicContent.title) = $event),
                            placeholder: "请输入题目标题",
                            style: {"width":"400px"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    extra: _withCtx(() => [
                      _createVNode(_component_a_space, { size: "medium" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            onClick: _withModifiers(($event: any) => (addTopics(index + 1)), ["stop"])
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("添加题目")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_a_button, {
                            status: "danger",
                            onClick: _withModifiers(($event: any) => (deleteTopics(index)), ["stop"])
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode(" 删除题目 ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_collapse, { "default-active-key": ['0'] }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topic.topicContent.options, (option, optionIndex) => {
                            return (_openBlock(), _createBlock(_component_a_collapse_item, { key: optionIndex }, {
                              header: _withCtx(() => [
                                _createElementVNode("span", null, "选项 " + _toDisplayString(optionIndex + 1), 1)
                              ]),
                              extra: _withCtx(() => [
                                _createVNode(_component_a_space, { size: "medium" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_button, {
                                      onClick: _withModifiers(($event: any) => (
                      addTopicOption(topic.topicContent, optionIndex + 1)
                    ), ["stop"])
                                    }, {
                                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode(" 添加选项 ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    _createVNode(_component_a_button, {
                                      status: "danger",
                                      onClick: _withModifiers(($event: any) => (
                      deleteTopicOption(topic.topicContent, optionIndex)
                    ), ["stop"])
                                    }, {
                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                        _createTextVNode(" 删除选项 ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { label: "选项 key" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      modelValue: option.key,
                                      "onUpdate:modelValue": ($event: any) => ((option.key) = $event),
                                      placeholder: "请输入选项 key"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_form_item, { label: "选项值" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      modelValue: option.value,
                                      "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                                      placeholder: "请输入选项值"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_form_item, { label: "选项结果" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      modelValue: option.result,
                                      "onUpdate:modelValue": ($event: any) => ((option.result) = $event),
                                      placeholder: "请输入选项结果"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_form_item, { label: "选项得分" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input_number, {
                                      modelValue: option.score,
                                      "onUpdate:modelValue": ($event: any) => ((option.score) = $event),
                                      placeholder: "请输入选项得分"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          _createVNode(_component_a_button, {
                            type: "dashed",
                            block: "",
                            onClick: ($event: any) => (
                addTopicOption(topic.topicContent, topic.topicContent.length)
              ),
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("底部添加选项 ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "dashed",
              block: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (addTopics(topicDTO.value.length)))
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" 底部添加题目 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]))
  ]))
}
}

})