<template>
  <!-- 加载中提示 -->
  <div v-if="isLoading" class="loading-container">
    加载中，请稍候...
    <icon-loading />
  </div>
  <div v-else id="appStatisticPage">
    <h2>热门应用统计</h2>
    <v-chart
      :option="appAnswerCountOptions"
      style="height: 300px; width: 800px"
    />
    <h2>应用结果统计</h2>
    <div class="search-bar">
      <a-select
        v-model="selectedAppId"
        :options="searchOptions"
        :style="{ width: '320px' }"
        placeholder="请选择应用名称"
        allow-search
      />
      <a-button
        type="primary"
        :disabled="disableStatus"
        @click="loadAppAnswerResultCountData(selectedAppId)"
        >查询
      </a-button>
    </div>
    <div style="margin-bottom: 16px" />
    <v-chart :option="appAnswerResultCountOptions" style="height: 300px" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import {
  getAppAnswerCount,
  getAppAnswerResultCount,
} from "@/api/appStatisticController";
import VChart from "vue-echarts";
import "echarts";
import { listAppByPage } from "@/api/appController";

const appAnswerCountList = ref<API.AppAnswerCountDTO[]>([]);
const appAnswerResultCountList = ref<API.AppAnswerResultCountDTO[]>([]);

const searchOptions = ref<API.AppQueryRequest>();
const selectedAppId = ref(); // 选择的应用 ID
const disableStatus = ref(true); // 禁用状态
const isLoading = ref(true);

// 监听 selectedAppId 变化，当值改变时将按钮重新启用
watch(selectedAppId, (newId, oldId) => {
  if (newId !== oldId) {
    disableStatus.value = false; // 启用按钮
  }
});

const loadAppOptions = async () => {
  const res = await listAppByPage({});
  if (res.data.code === 0) {
    searchOptions.value = res.data.data?.records.map((app) => ({
      label: app.appName, // 显示的名称
      value: app.id, // 实际的值
    }));
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + (res.data.message || "未知错误"));
    isLoading.value = false;
  }
};

/**
 * 加载数据 热门app
 */
const loadAppAnswerCountData = async () => {
  const res = await getAppAnswerCount();
  if (res.data.code === 0) {
    appAnswerCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};

// 统计选项
const appAnswerCountOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: appAnswerCountList.value.map((item) => item.appName),
      name: "应用 id",
    },
    yAxis: {
      type: "value",
      name: "用户答案数",
    },
    series: [
      {
        type: "bar",
        data: appAnswerCountList.value.map((item, index) => ({
          value: item.answerCount,
          itemStyle: {
            color: getColorByIndex(index), // 根据索引设置颜色
          },
        })),
      },
    ],
  };
});

/**
 * 加载数据 热门答案
 */
const loadAppAnswerResultCountData = async (appId: string) => {
  if (!appId) {
    return;
  }
  const res = await getAppAnswerResultCount({
    appId: appId as any,
  });
  if (res.data.code === 0) {
    appAnswerResultCountList.value = res.data.data || [];
    disableStatus.value = true;
  } else {
    message.error("获取数据失败，" + res.data.message);
    disableStatus.value = true;
  }
};

// 统计选项
const appAnswerResultCountOptions = computed(() => {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "应用答案结果分布",
        type: "pie",
        radius: "50%",
        data: appAnswerResultCountList.value.map((item) => {
          return { value: item.resultCount, name: item.resultName };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
});

function getColorByIndex(index: number) {
  const colors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452",
  ];
  return colors[index % colors.length]; // 循环使用颜色
}

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppAnswerCountData();
});

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppAnswerResultCountData("");
});

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppOptions();
});
</script>

<style scoped></style>
