<template>
  <a-space direction="vertical" :style="{ width: '100%' }">
    <a-space>
      <a-upload
        ref="imageUrlRef"
        :fileList="file ? [file] : []"
        :show-file-list="false"
        :auto-upload="false"
        @change="onChange"
        @progress="onProgress"
      >
        <template #upload-button>
          <div
            :class="`arco-upload-list-item${
              file && file.status === 'error'
                ? ' arco-upload-list-item-error'
                : ''
            }`"
          >
            <div
              class="arco-upload-list-picture custom-upload-avatar"
              v-if="file && file.url"
            >
              <img :src="file.url" />
              <div class="arco-upload-list-picture-mask">
                <IconEdit />
              </div>
              <a-progress
                v-if="file.status === 'uploading' && file.percent < 100"
                :percent="file.percent"
                type="circle"
                size="mini"
                :style="{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                }"
              />
            </div>
            <div class="arco-upload-picture-card" v-else>
              <div class="arco-upload-picture-card-text">
                <IconPlus />
                <div style="margin-top: 10px; font-weight: 600">上传图片</div>
              </div>
            </div>
          </div>
        </template>
      </a-upload>
      <a-button
        type="outline"
        size="large"
        :loading="loading"
        :disabled="disable || !file"
        @click="handleUpload"
        style="flex: auto; margin-top: 50px"
        >确认上传
      </a-button>
      <!-- 清除按钮 -->
      <a-button
        type="outline"
        size="large"
        status="warning"
        @click="handleClear"
        style="flex: auto; margin-top: 50px"
        >清除图片
      </a-button>
    </a-space>
  </a-space>
</template>

<script setup lang="ts">
import { ref, defineEmits, watch, defineProps, defineExpose } from "vue";
import { IconEdit, IconPlus } from "@arco-design/web-vue/es/icon";
import message from "@arco-design/web-vue/es/message";
import { upload } from "@/api/uploadController";
import { FileItem } from "@arco-design/web-vue";

// 用于控制上传按钮的加载状态
const loading = ref(false);
const imageUrl = ref<string | null>();
const file = ref<FileItem | null>(null);
const disable = ref(false);
const emit = defineEmits(["input"]);

const props = defineProps<{
  value?: string;
}>();

const isInitialLoad = ref(true);

watch(
  () => props.value,
  (newValue) => {
    if (newValue) {
      imageUrl.value = newValue;
      file.value = { url: newValue, status: "done", name: "existing-image" };
      disable.value = true;
      isInitialLoad.value = false;
    }
  }
);

// 清除文件和图片URL
const handleClear = () => {
  file.value = null; // 清除文件
  imageUrl.value = null; // 清除图片 URL
  disable.value = false; // 启用上传按钮
  emit("input", null); // 触发事件通知父组件
};

// 使用 defineExpose 将 handleClear 方法暴露给父组件
defineExpose({
  handleClear,
});

// onChange和onProgress用于渲染展示伪上传的图片
const onChange = (_: any, currentFile: FileItem) => {
  file.value = currentFile.url ? currentFile : null;
  if (currentFile.url) {
    disable.value = false;
  }
};

const onProgress = (currentFile: FileItem) => {
  file.value = currentFile.url ? currentFile : null;
};

const handleUpload = async () => {
  if (!file.value) {
    message.error("请先选择一个文件上传");
    return;
  }

  loading.value = true;
  const res: any = await upload(file.value.file, {});
  if (res.data.code === 0) {
    imageUrl.value = res.data.data;
    message.success("上传成功！记得点击提交按钮哦！");
    disable.value = true;
    loading.value = false;
    emit("input", imageUrl.value);
  } else {
    message.error("上传失败: " + res.data.message);
    loading.value = false;
  }
};
</script>
