import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = {
  key: 1,
  style: { display: 'flex' }
}
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { style: { display: 'flex', alignItems: 'center', color: '#1D2129' } }

import { IconShareInternal } from "@arco-design/web-vue/es/icon";
import API from "@/api";
import { ref } from "vue";
import { useRouter } from "vue-router";
import ShareModal from "@/components/ShareModal.vue";

interface Props {
  app: API.AppVO;
  isLoading?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppCard',
  props: {
    app: { default: () => {
    return {};
  } },
    isLoading: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const doCardClick = () => {
  router.push(`/app/detail/${props.app.id}`);
};

// 分享弹窗的引用
const shareModalRef = ref();

// 分享链接
const shareLink = `${window.location.protocol}//${window.location.host}/app/detail/${props.app.id}`;

// 分享
const doShare = (e: Event) => {
  if (shareModalRef.value) {
    shareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode(" 加载中，请稍候... ")),
        _createVNode(_component_icon_loading)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_card, {
          class: "appCard",
          hoverable: "",
          onClick: doCardClick
        }, {
          actions: _withCtx(() => [
            _createElementVNode("span", {
              class: "icon-hover",
              onClick: doShare
            }, [
              _createVNode(_unref(IconShareInternal))
            ])
          ]),
          cover: _withCtx(() => [
            _createElementVNode("img", {
              style: { width: '100%', height: '100%' },
              alt: _ctx.app.appName,
              src: _ctx.app.appIcon
            }, null, 8, _hoisted_3)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_card_meta, {
              title: _ctx.app.appName,
              description: _ctx.app.appDesc
            }, {
              avatar: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_avatar, {
                    shape: "square",
                    size: 36,
                    "image-url": _ctx.app.userVO?.userAvatar,
                    style: { marginRight: '8px' }
                  }, null, 8, ["image-url"]),
                  _createVNode(_component_a_typography_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.app.userVO?.userName ?? "匿名用户"), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["title", "description"])
          ]),
          _: 1
        }),
        _createVNode(ShareModal, {
          link: shareLink,
          title: "应用分享",
          ref_key: "shareModalRef",
          ref: shareModalRef
        }, null, 512)
      ]))
}
}

})