import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import BasicLayout from "@/layouts/BasicLayout.vue";
import { useRoute } from "vue-router";
import { onMounted } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
/**
 * 全局初始化函数，有全局单次调用的代码，都可以写到这里
 */
const doInit = () => {
  console.log("知巢");
};

onMounted(() => {
  doInit();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(route).path.startsWith('/user'))
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createBlock(BasicLayout, { key: 1 }))
  ]))
}
}

})