import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "userCenter",
  style: {"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","height":"36vh"}
}

import { ref, watchEffect } from "vue";
import API from "@/api";
import {
  getUserVoById,
  updateMyUser,
} from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import PictureUploader from "@/components/PictureUploader.vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserCenter',
  setup(__props, { expose: __expose }) {

const loginUserStore = useLoginUserStore();
const isLoading = ref(false);
const form = ref({
  userName: "",
  userAvatar: "",
  userProfile: "",
} as API.UserUpdateMyRequest);

/**
 * 加载数据
 */
const loadData = async () => {
  if (!loginUserStore.loginUser.id) {
    return;
  }
  const res = await getUserVoById({
    id: loginUserStore.loginUser.id as number,
  });
  if (res.data.code === 0 && res.data.data) {
    form.value = res.data.data;
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};
// 用于接收uploader组件传来的图片地址
const updateUserAvatar = (url: string) => {
  form.value.userAvatar = url; // 更新表单中的 appIcon 属性
};
// 获取旧数据
watchEffect(() => {
  loadData();
});

// 是否可见
const visible = ref(false);

// 打开弹窗
const openModal = () => {
  visible.value = true;
};

// 暴露函数给父组件
__expose({
  openModal,
});

// 关闭弹窗
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async () => {
  isLoading.value = true;
  // 校验输入
  if (form.value.userName === "") {
    message.error("用户名不能为空！");
    isLoading.value = false;
    return;
  }
  // 发起更新请求
  const res = await updateMyUser(form.value);
  if (res.data.code === 0) {
    message.success("修改成功！准备刷新...");
    // 同步更新 Pinia 和 localStorage
    await loginUserStore.fetchLoginUser();
    // 延迟跳转至主页并刷新页面
    setTimeout(() => {
      router.replace("/").then(() => {
        window.location.reload();
      });
    }, 300);
    visible.value = false;
  } else {
    message.error("修改失败，" + (res.data.message || "未知错误"));
  }

  isLoading.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: visible.value,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((visible).value = $event)),
    title: "个人中心",
    footer: false,
    onCancel: handleCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          model: form.value,
          style: { width: '450px' },
          "label-align": "left",
          "auto-label-width": "",
          onSubmit: handleSubmit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              field: "userName",
              label: "用户账号："
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: _unref(loginUserStore).loginUser.userAccount,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(loginUserStore).loginUser.userAccount) = $event)),
                  placeholder: "请输入用户名",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "userName",
              label: "用户昵称："
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: form.value.userName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.userName) = $event)),
                  placeholder: "请输入用户名"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "userAvatar",
              label: "用户头像："
            }, {
              default: _withCtx(() => [
                _createVNode(PictureUploader, {
                  modelValue: form.value.userAvatar,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.userAvatar) = $event)),
                  onInput: updateUserAvatar,
                  value: form.value.userAvatar
                }, null, 8, ["modelValue", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "userProfile",
              label: "用户简介："
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: form.value.userProfile,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.userProfile) = $event)),
                  placeholder: "请输入用户简介"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "userRole",
              label: "用户身份：",
              disabled: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: _unref(loginUserStore).loginUser.userRole,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(loginUserStore).loginUser.userRole) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px","margin":"0 auto"},
              disabled: isLoading.value
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" 提交 ")
              ])),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})