import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = {
  key: 1,
  id: "appStatisticPage"
}
const _hoisted_3 = { class: "search-bar" }

import { computed, ref, watch, watchEffect } from "vue";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import {
  getAppAnswerCount,
  getAppAnswerResultCount,
} from "@/api/appStatisticController";
import VChart from "vue-echarts";
import "echarts";
import { listAppByPage } from "@/api/appController";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppStatisticPage',
  setup(__props) {

const appAnswerCountList = ref<API.AppAnswerCountDTO[]>([]);
const appAnswerResultCountList = ref<API.AppAnswerResultCountDTO[]>([]);

const searchOptions = ref<API.AppQueryRequest>();
const selectedAppId = ref(); // 选择的应用 ID
const disableStatus = ref(true); // 禁用状态
const isLoading = ref(true);

// 监听 selectedAppId 变化，当值改变时将按钮重新启用
watch(selectedAppId, (newId, oldId) => {
  if (newId !== oldId) {
    disableStatus.value = false; // 启用按钮
  }
});

const loadAppOptions = async () => {
  const res = await listAppByPage({});
  if (res.data.code === 0) {
    searchOptions.value = res.data.data?.records.map((app) => ({
      label: app.appName, // 显示的名称
      value: app.id, // 实际的值
    }));
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + (res.data.message || "未知错误"));
    isLoading.value = false;
  }
};

/**
 * 加载数据 热门app
 */
const loadAppAnswerCountData = async () => {
  const res = await getAppAnswerCount();
  if (res.data.code === 0) {
    appAnswerCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败，" + res.data.message);
  }
};

// 统计选项
const appAnswerCountOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: appAnswerCountList.value.map((item) => item.appName),
      name: "应用 id",
    },
    yAxis: {
      type: "value",
      name: "用户答案数",
    },
    series: [
      {
        type: "bar",
        data: appAnswerCountList.value.map((item, index) => ({
          value: item.answerCount,
          itemStyle: {
            color: getColorByIndex(index), // 根据索引设置颜色
          },
        })),
      },
    ],
  };
});

/**
 * 加载数据 热门答案
 */
const loadAppAnswerResultCountData = async (appId: string) => {
  if (!appId) {
    return;
  }
  const res = await getAppAnswerResultCount({
    appId: appId as any,
  });
  if (res.data.code === 0) {
    appAnswerResultCountList.value = res.data.data || [];
    disableStatus.value = true;
  } else {
    message.error("获取数据失败，" + res.data.message);
    disableStatus.value = true;
  }
};

// 统计选项
const appAnswerResultCountOptions = computed(() => {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "应用答案结果分布",
        type: "pie",
        radius: "50%",
        data: appAnswerResultCountList.value.map((item) => {
          return { value: item.resultCount, name: item.resultName };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
});

function getColorByIndex(index: number) {
  const colors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452",
  ];
  return colors[index % colors.length]; // 循环使用颜色
}

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppAnswerCountData();
});

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppAnswerResultCountData("");
});

/**
 * 参数改变时触发数据的重新加载
 */
watchEffect(() => {
  loadAppOptions();
});

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!

  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createTextVNode(" 加载中，请稍候... ")),
        _createVNode(_component_icon_loading)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("h2", null, "热门应用统计", -1)),
        _createVNode(_unref(VChart), {
          option: appAnswerCountOptions.value,
          style: {"height":"300px","width":"800px"}
        }, null, 8, ["option"]),
        _cache[5] || (_cache[5] = _createElementVNode("h2", null, "应用结果统计", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_select, {
            modelValue: selectedAppId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedAppId).value = $event)),
            options: searchOptions.value,
            style: { width: '320px' },
            placeholder: "请选择应用名称",
            "allow-search": ""
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_a_button, {
            type: "primary",
            disabled: disableStatus.value,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (loadAppAnswerResultCountData(selectedAppId.value)))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("查询 ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"margin-bottom":"16px"} }, null, -1)),
        _createVNode(_unref(VChart), {
          option: appAnswerResultCountOptions.value,
          style: {"height":"300px"}
        }, null, 8, ["option"])
      ]))
}
}

})