<template>
  <a-form
    :model="formSearchParams"
    :style="{ marginBottom: '20px' , display: 'flex', justifyContent: 'center'}"
    layout="inline"
    @submit="doSearch"
  >
    <a-form-item field="appName" label="应用名称">
      <a-input
        v-model="formSearchParams.appName"
        placeholder="请输入应用名称"
        allow-clear
      />
    </a-form-item>
    <a-form-item field="appDesc" label="应用描述">
      <a-input
        v-model="formSearchParams.appDesc"
        placeholder="请输入应用描述"
        allow-clear
      />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" style="width: 100px">
        搜索
      </a-button>
    </a-form-item>
  </a-form>
  <!-- 加载中提示 -->
  <div v-if="isLoading" class="loading-container">
    加载中，请稍候...
    <icon-loading />
  </div>
  <a-table
    v-else
    :columns="columns"
    :data="dataList"
    :pagination="{
      showTotal: true,
      pageSize: searchParams.pageSize,
      current: searchParams.current,
      sortField: searchParams.sortField,
      sortOrder: searchParams.sortOrder,
      total,
    }"
    @page-change="onPageChange"
  >
    <template #appIcon="{ record }">
      <a-image width="64" :src="record.appIcon" />
    </template>
    <template #appType="{ record }">
      {{ APP_TYPE_MAP[record.appType] }}
    </template>
    <template #scoringStrategy="{ record }">
      {{ APP_SCORING_STRATEGY_MAP[record.scoringStrategy] }}
    </template>
    <template #reviewStatus="{ record }">
      {{ REVIEW_STATUS_MAP[record.reviewStatus] }}
    </template>
    <template #reviewTime="{ record }">
      {{
        record.reviewTime &&
        dayjs(record.reviewTime).format("YYYY-MM-DD HH:mm:ss")
      }}
    </template>
    <template #createTime="{ record }">
      {{ dayjs.utc(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #updateTime="{ record }">
      {{ dayjs.utc(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
    </template>
    <template #optional="{ record }">
      <a-space>
        <a-button
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.PASS"
          status="success"
          @click="doReview(record, REVIEW_STATUS_ENUM.PASS, '通过')"
        >
          通过
        </a-button>
        <a-button
          v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.REJECT"
          status="warning"
          @click="doReview(record, REVIEW_STATUS_ENUM.REJECT, '不符合上架要求')"
        >
          拒绝
        </a-button>
        <a-popconfirm
          content="你确定要删除吗?"
          type="warning"
          position="right"
          ok-text="确定"
          @ok="doDelete(record)"
          :ok-loading="isLoading"
        >
          <a-button status="danger">删除</a-button>
        </a-popconfirm>
      </a-space>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import {
  deleteApp,
  doAppReview,
  listAppByPage,
} from "@/api/appController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import {
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_MAP,
  REVIEW_STATUS_ENUM,
  REVIEW_STATUS_MAP,
} from "@/constant/app";
import utc from "dayjs/plugin/utc";

const formSearchParams = ref<API.AppQueryRequest>({});

dayjs.extend(utc);

// 初始化搜索条件（不应该被修改）
const initSearchParams = {
  current: 1,
  pageSize: 10,
  sortField: "updateTime",
  sortOrder: "",
};

const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});

const dataList = ref<API.App[]>([]);
const total = ref<number>(0);
const isLoading = ref(true);

/**
 * 加载数据
 */
const loadData = async () => {
  const res = await listAppByPage(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 执行搜索
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param record
 */
const doDelete = async (record: API.App) => {
  if (!record.id) {
    return;
  }

  const res = await deleteApp({
    id: record.id,
  });
  if (res.data.code === 0) {
    await loadData();
  } else {
    message.error("删除失败，" + res.data.message);
  }
};

/**
 * 审核
 * @param record
 * @param reviewStatus
 */
const doReview = async (
  record: API.App,
  reviewStatus: number,
) => {
  if (!record.id) {
    return;
  }

  const res = await doAppReview({
    id: record.id,
    reviewStatus,
  });
  if (res.data.code === 0) {
    await loadData();
  } else {
    message.error("审核失败，" + res.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

// 表格列配置
const columns = [
  {
    title: "id",
    dataIndex: "id",
    align: "center",
    width: 115,
  },
  {
    title: "名称",
    dataIndex: "appName",
    align: "center",
    width: 100,
  },
  {
    title: "描述",
    dataIndex: "appDesc",
    align: "center",
    width: 110,
    tooltip: "true",
    ellipsis: "true",
  },
  {
    title: "图标",
    dataIndex: "appIcon",
    slotName: "appIcon",
    align: "center",
    width: 100,
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
    align: "center",
    width: 90,
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
    align: "center",
    width: 90,
  },
  {
    title: "审核状态",
    dataIndex: "reviewStatus",
    slotName: "reviewStatus",
    align: "center",
    width: 100,
  },
  {
    title: "审核时间",
    dataIndex: "reviewTime",
    slotName: "reviewTime",
    align: "center",
    width: 115,
  },
  {
    title: "审核人",
    dataIndex: "reviewerId",
    align: "center",
    width: 115,
  },
  {
    title: "用户 id",
    dataIndex: "userId",
    align: "center",
    width: 115,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
    align: "center",
    width: 115,
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
    align: "center",
    width: 115,
  },
  {
    title: "操作",
    slotName: "optional",
    align: "center",
    minWidth: 250,
  },
];
</script>
