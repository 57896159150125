// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** 上传图片 POST /aliUpload */
export async function upload(image: File, body: Record<string, any> = {}, options: Record<string, any> = {}) {
  const formData = new FormData();

  formData.append('image', image);

  Object.keys(body).forEach((ele) => {
    const item = body[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.BaseResponseString>('/api/aliUpload', {
    method: 'POST',
    data: formData,
    // @ts-ignore
    requestType: 'form',
    ...options,
  });
}