import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}

import { ref, watchEffect } from "vue";
import {
  deleteUserAnswer,
  listMyUserAnswerVoByPage,
} from "@/api/userAnswerController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/app";
import utc from "dayjs/plugin/utc";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyAnswerPage',
  setup(__props) {

const formSearchParams = ref<API.UserAnswerQueryRequest>({});
dayjs.extend(utc);
// 初始化搜索条件（不应该被修改）
const initSearchParams = {
  current: 1,
  pageSize: 10,
  sortField: "updateTime",
  sortOrder: "",
};

const searchParams = ref<API.UserAnswerQueryRequest>({
  ...initSearchParams,
});
const dataList = ref<API.UserAnswerVO[]>([]);
const total = ref<number>(0);
const isLoading = ref(true);
const isLoadingButton = ref(true);

/**
 * 加载数据
 */
const loadData = async () => {
  const res = await listMyUserAnswerVoByPage(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 执行搜索
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param record
 */
const doDelete = async (record: API.UserAnswer) => {
  isLoadingButton.value = true;
  if (!record.id) {
    return;
  }
  const res = await deleteUserAnswer({
    id: record.id,
  });
  if (res.data.code === 0) {
    message.success("删除成功");
    await loadData();
    isLoadingButton.value = false;
  } else {
    message.error("删除失败，" + res.data.message);
    isLoadingButton.value = false;
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

// 表格列配置
const columns = [
  {
    title: "id",
    align: "center",
    dataIndex: "id",
    width: 115,
  },
  {
    title: "选项",
    align: "center",
    dataIndex: "choices",
    width: 90,
    tooltip: "true",
    ellipsis: "true",
  },
  {
    title: "结果 id",
    dataIndex: "resultId",
    align: "center",
    width: 115,
  },
  {
    title: "结果名称",
    dataIndex: "resultName",
    align: "center",
    width: 115,
    tooltip: "true",
    ellipsis: "true",
  },
  {
    title: "描述",
    align: "center",
    dataIndex: "resultDesc",
    width: 250,
    tooltip: "true",
    ellipsis: "true",
  },
  {
    title: "图片",
    align: "center",
    dataIndex: "resultPicture",
    slotName: "resultPicture",
    minWidth: 80,
  },
  {
    title: "得分",
    dataIndex: "resultScore",
    align: "center",
    minWidth: 60,
  },
  {
    title: "应用 id",
    dataIndex: "appId",
    align: "center",
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
    align: "center",
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
    align: "center",
    width: 115,
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "操作",
    slotName: "optional",
    align: "center",
    minWidth: 80,
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_form, {
      model: formSearchParams.value,
      style: { marginBottom: '20px', display: 'flex', justifyContent: 'center' },
      layout: "inline",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "resultName",
          label: "结果名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: formSearchParams.value.resultName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.resultName) = $event)),
              placeholder: "请输入结果名称",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultDesc",
          label: "结果描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: formSearchParams.value.resultDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.resultDesc) = $event)),
              placeholder: "请输入结果描述",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appId",
          label: "应用 id"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: formSearchParams.value.appId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formSearchParams.value.appId) = $event)),
              placeholder: "请输入应用 id",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"100px"}
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" 搜索 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_table, {
          key: 1,
          columns: columns,
          data: dataList.value,
          pagination: {
      showTotal: true,
      pageSize: searchParams.value.pageSize,
      current: searchParams.value.current,
      sortField: searchParams.value.sortField,
      sortOrder: searchParams.value.sortOrder,
      total: total.value,
    },
          onPageChange: onPageChange
        }, {
          resultPicture: _withCtx(({ record }) => [
            _createVNode(_component_a_image, {
              width: "64",
              src: record.resultPicture
            }, null, 8, ["src"])
          ]),
          appType: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(APP_TYPE_MAP)[record.appType]), 1)
          ]),
          scoringStrategy: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(APP_SCORING_STRATEGY_MAP)[record.scoringStrategy]), 1)
          ]),
          createTime: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(dayjs).utc(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
          ]),
          updateTime: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(dayjs).utc(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
          ]),
          optional: _withCtx(({ record }) => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_popconfirm, {
                  content: "你确定要删除吗?",
                  type: "warning",
                  position: "right",
                  "ok-text": "确定",
                  onOk: ($event: any) => (doDelete(record)),
                  "ok-loading": isLoading.value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { status: "danger" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("删除")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onOk", "ok-loading"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data", "pagination"]))
  ], 64))
}
}

})