<template>
  <div id="doAnswerPage" style="width: 800px">
    <!-- 加载中提示 -->
    <div v-if="isLoading" class="loading-container">
      加载中，请稍候...
      <icon-loading />
    </div>
    <a-card v-else>
      <!-- 应用信息 -->
      <h1>{{ app.appName }}</h1>
      <p>描述：{{ app.appDesc }}</p>
      <p>题目数量：{{ topicDTO.length }}</p>

      <!-- 当前题目 -->
      <h2 style="margin-bottom: 16px">
        {{ current }}、{{ currentTopic?.title }}
      </h2>
      <div>
        <a-radio-group
          direction="vertical"
          v-model="currentAnswer.userAnswer"
          :options="topicOptions"
          @change="doRadioChange"
        />
      </div>

      <!-- 按钮区域 -->
      <div style="margin-top: 24px">
        <a-space size="large">
          <a-button
            type="primary"
            circle
            v-if="current < topicDTO.length"
            :disabled="!currentAnswer.userAnswer"
            @click="nextQuestion"
          >
            下一题
          </a-button>
          <a-button
            type="primary"
            v-if="current === topicDTO.length"
            :loading="submitting"
            circle
            :disabled="!currentAnswer.userAnswer"
            @click="doSubmit"
          >
            {{ submitting ? "评分中" : "查看结果" }}
          </a-button>
          <a-button v-if="current > 1" circle @click="prevQuestion">
            上一题
          </a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  defineProps,
  reactive,
  ref,
  watchEffect,
  withDefaults,
} from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import { listTopicByPage } from "@/api/topicController";
import message from "@arco-design/web-vue/es/message";
import { getAppVoById } from "@/api/appController";
import {
  addUserAnswer,
  generateUserAnswerId,
} from "@/api/userAnswerController";

interface Props {
  appId: string;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});

const router = useRouter();
const isLoading = ref(false);

const app = ref<API.AppVO>({});
const topicDTO = ref<API.Topic[]>([]);
const current = ref(1);
const currentTopic = ref<API.TopicContent>({});
const topicOptions = computed(() => {
  return currentTopic.value?.options
    ? currentTopic.value.options.map((option) => {
        return {
          label: `${option.key}. ${option.value}`,
          value: option.key,
        };
      })
    : [];
});
const currentAnswer = ref<API.TopicAnswerDTO>({
  topicId: 0,
  userAnswer: "",
});
const answerList = reactive<API.TopicAnswerDTO[]>([]);
const submitting = ref(false);
const id = ref<number>();

const generateId = async () => {
  const res = await generateUserAnswerId();
  if (res.data.code === 0) {
    id.value = res.data.data as any;
  } else {
    message.error("获取唯一 id 失败，" + res.data.message);
  }
};

watchEffect(() => {
  generateId();
});

/**
 * 加载数据
 */
const loadData = async () => {
  isLoading.value = true;
  if (!props.appId) {
    isLoading.value = false;
    return;
  }

  let res: any = await getAppVoById({ id: props.appId as any });
  if (res.data.code === 0) {
    app.value = res.data.data as any;
  } else {
    message.error("获取应用失败，" + res.data.message);
  }

  res = await listTopicByPage({
    appId: props.appId as any,
    current: 1,
    pageSize: 100,
    sortField: "createTime",
    sortOrder: "descend",
  });

  if (res.data.code === 0 && res.data.data?.records) {
    // 获取题目列表
    topicDTO.value = res.data.data.records || [];

    // 初始化答案列表
    answerList.splice(0); // 清空原有数据
    topicDTO.value.forEach((topic) => {
      answerList.push({
        topicId: topic.topicId || 0,
        userAnswer: "",
      });
    });

    // 如果有题目，设置当前题目
    if (topicDTO.value.length > 0) {
      setCurrentTopic();
    }
  } else {
    message.error("获取题目失败，" + (res.data.message || "未知错误"));
    topicDTO.value = []; // 确保初始化为空数组
  }
  isLoading.value = false;
};

// 设置当前题目的方法
const setCurrentTopic = () => {
  if (topicDTO.value.length > 0 && current.value <= topicDTO.value.length) {
    const topic = topicDTO.value[current.value - 1];

    // 直接解析topicContent字符串为TopicContent类型
    try {
      if (topic.topicContent) {
        currentTopic.value = JSON.parse(topic.topicContent) as API.TopicContent;
      } else {
        currentTopic.value = { title: "", options: [] };
      }
    } catch (e) {
      console.error("解析题目内容失败", e);
      currentTopic.value = { title: "", options: [] };
    }

    // 设置当前答案
    currentAnswer.value = answerList[current.value - 1];
  }
};

// 监听 appId 变化加载数据
watchEffect(() => {
  if (props.appId) {
    loadData();
  }
});

// 监听当前题目索引变化
watchEffect(() => {
  if (topicDTO.value.length > 0) {
    setCurrentTopic();
  }
});

// 单选处理函数
const doRadioChange = (value: string) => {
  if (currentAnswer.value) {
    currentAnswer.value.userAnswer = value;
  }
};

const nextQuestion = () => {
  if (current.value < topicDTO.value.length) {
    current.value += 1;
  }
};

const prevQuestion = () => {
  if (current.value > 1) {
    current.value -= 1;
  }
};

/**
 * 提交答案
 */
const doSubmit = async () => {
  if (!props.appId || answerList.length === 0) {
    message.error("没有可提交的答案");
    return;
  }

  // 检查是否有未回答的题目
  const unansweredIndex = answerList.findIndex((answer) => !answer.userAnswer);
  if (unansweredIndex !== -1) {
    current.value = unansweredIndex + 1;
    message.warning(`第 ${unansweredIndex + 1} 题尚未回答`);
    return;
  }

  submitting.value = true;
  try {
    const res = await addUserAnswer({
      id: id.value as any,
      appId: props.appId as any,
      userAnswer: answerList,
    });

    if (res.data.code === 0 && res.data.data) {
      await router.push(`/answer/result/${res.data.data}`);
    } else {
      message.error("提交答案失败，" + (res.data.message || "未知错误"));
    }
  } catch (error) {
    console.error("提交答案出错", error);
    message.error("提交答案出错，请稍后重试");
  } finally {
    submitting.value = false;
  }
};
</script>

<style scoped></style>
