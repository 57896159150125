<template>
  <div id="addQuestionPage">
    <h2 style="margin-bottom: 32px; text-align: center">设置题目</h2>
    <!-- 加载中提示 -->
    <div v-if="isLoading" class="loading-container">
      加载中，请稍候...
      <icon-loading />
    </div>
    <a-form
      v-else
      :model="topicDTO"
      :style="{ width: '900px' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <div style="display: flex; justify-content: flex-start">
        <a-space size="large">
          <a-form-item label="题目列表">
            <a-space size="large">
              <a-button status="success" @click="addTopics(topicDTO.length)">
                底部添加题目
              </a-button>
              <!-- AI 生成抽屉 -->
              <AiGenerateQuestionDrawer
                :appId="appId"
                :onSuccess="onAiGenerateSuccess"
                :onSSESuccess="onAiGenerateSuccessSSE"
                :onSSEClose="onSSEClose"
                :onSSEStart="onSSEStart"
              />
              <a-button
                type="primary"
                html-type="submit"
                style="width: 120px"
                :disabled="buttonDisable"
              >
                提交
              </a-button>
              <a-button
                type="primary"
                status="danger"
                style="width: 120px"
                @click="clearAllTopics"
              >
                清空所有题目
              </a-button>
            </a-space>
          </a-form-item>
          <a-form-item label="应用 id">
            {{ appId }}
          </a-form-item>
        </a-space>
      </div>
      <a-collapse :default-active-key="['0']" destroy-on-hide>
        <!-- 遍历题目 -->
        <a-collapse-item v-for="(topic, index) in topicDTO" :key="index">
          <template #header>
            <a-space size="small">
              {{ index + 1 }}:
              <!-- 可编辑题目标题 -->
              <a-input
                v-model="topic.topicContent.title"
                placeholder="请输入题目标题"
                style="width: 400px"
              />
            </a-space>
          </template>
          <!-- 操作按钮 -->
          <template #extra>
            <a-space size="medium">
              <a-button @click.stop="addTopics(index + 1)">添加题目</a-button>
              <a-button status="danger" @click.stop="deleteTopics(index)">
                删除题目
              </a-button>
            </a-space>
          </template>
          <!-- 选项列表 -->
          <a-collapse :default-active-key="['0']">
            <a-collapse-item
              v-for="(option, optionIndex) in topic.topicContent.options"
              :key="optionIndex"
            >
              <template #header>
                <!-- 可编辑选项标题 -->
                <span>选项 {{ optionIndex + 1 }}</span>
              </template>
              <template #extra>
                <!-- 选项操作按钮 -->
                <a-space size="medium">
                  <a-button
                    @click.stop="
                      addTopicOption(topic.topicContent, optionIndex + 1)
                    "
                  >
                    添加选项
                  </a-button>
                  <a-button
                    status="danger"
                    @click.stop="
                      deleteTopicOption(topic.topicContent, optionIndex)
                    "
                  >
                    删除选项
                  </a-button>
                </a-space>
              </template>
              <!-- 选项内容 -->
              <a-form-item label="选项 key">
                <a-input v-model="option.key" placeholder="请输入选项 key" />
              </a-form-item>
              <a-form-item label="选项值">
                <a-input v-model="option.value" placeholder="请输入选项值" />
              </a-form-item>
              <a-form-item label="选项结果">
                <a-input v-model="option.result" placeholder="请输入选项结果" />
              </a-form-item>
              <a-form-item label="选项得分">
                <a-input-number
                  v-model="option.score"
                  placeholder="请输入选项得分"
                />
              </a-form-item>
            </a-collapse-item>
            <!-- 添加选项按钮 -->
            <a-button
              type="dashed"
              block
              @click="
                addTopicOption(topic.topicContent, topic.topicContent.length)
              "
              style="width: 100%"
              >底部添加选项
            </a-button>
          </a-collapse>
        </a-collapse-item>
      </a-collapse>
      <!-- 添加题目按钮 -->
      <a-button type="dashed" block @click="addTopics(topicDTO.length)">
        底部添加题目
      </a-button>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import {
  addTopic,
  updateTopic,
  listTopicByPage,
  deleteTopic,
} from "@/api/topicController";
import message from "@arco-design/web-vue/es/message";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";

interface Props {
  appId: string;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => {
    return "";
  },
});

const router = useRouter();
const isLoading = ref(true);

// 题目内容结构（题目列表）
const topicDTO = ref<API.TopicDTO[]>([
  {
    topicId: 0,
    topicContent: {
      title: "",
      options: [
        {
          key: "",
          value: "",
          result: "",
          score: 0,
        },
      ],
    },
    difficulty: 0,
  },
]);

/**
 * 添加题目
 * @param index
 */
const addTopics = (index: number) => {
  topicDTO.value.splice(index, 0, {
    topicContent: {
      title: "",
      options: [
        {
          key: "",
          value: "",
          result: "",
          score: 0,
        },
      ],
    },
    difficulty: 0,
  });
};

// 存储被删除的题目 ID
const deletedTopicIds = ref<number[]>([]);

/**
 * 删除题目
 * @param index
 */
const deleteTopics = (index: number) => {
  const deletedTopic = topicDTO.value[index];
  if (deletedTopic.topicId) {
    deletedTopicIds.value.push(deletedTopic.topicId);
  }
  topicDTO.value.splice(index, 1);
};

/**
 * 添加题目选项
 * @param topic
 * @param index
 */
const addTopicOption = (topic: API.TopicContent, index: number) => {
  if (!topic.options) {
    topic.options = [];
  }
  topic.options.splice(index, 0, {
    key: "",
    value: "",
    result: "",
    score: 0,
  });
};

/**
 * 清空所有题目
 */
const clearAllTopics = () => {
  deletedTopicIds.value.push(
    ...topicDTO.value
      .filter((topic) => topic.topicId)
      .map((topic) => topic.topicId as number)
  );
  topicDTO.value = [];
  console.log("清空所有题目");
};

/**
 * 删除题目选项
 * @param topic
 * @param index
 */
const deleteTopicOption = (topic: API.TopicContent, index: number) => {
  if (!topic.options) {
    topic.options = [];
  }
  topic.options.splice(index, 1);
};

const oldTopic = ref<API.Topic>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  const res = await listTopicByPage({
    appId: props.appId as any,
    current: 1,
    pageSize: 100,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    // 清空当前数据
    topicDTO.value = [];
    // 遍历所有记录
    res.data.data.records.forEach((record: API.Topic) => {
      try {
        // 解析 topicContent 字符串为对象
        const parsedTopicContent = JSON.parse(record.topicContent ?? "{}");
        // 添加到 topicDTO
        topicDTO.value.push({
          topicId: record.topicId,
          topicContent: {
            title: parsedTopicContent.title ?? "",
            options: parsedTopicContent.options ?? [],
          },
          difficulty: record.difficulty,
        });
        // 更新 oldTopic
        if (!oldTopic.value) {
          oldTopic.value = record;
        }
      } catch (error) {
        console.error("解析 topicContent 失败:", error);
        // 提供默认值
        topicDTO.value.push({
          topicContent: {
            title: "",
            options: [],
          },
        });
      }
    });
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

// 获取旧数据
watchEffect(() => {
  loadData();
});

const buttonDisable = ref(false);
/**
 * 提交
 */
/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId || !topicDTO.value) {
    return;
  }
  buttonDisable.value = true;

  let updateResult: any = null;
  let createResult: any = null;
  let deleteResult: any = null;

  try {
    // 处理删除题目
    if (deletedTopicIds.value.length > 0) {
      deleteResult = await deleteTopic({
        appId: props.appId as any,
        topicIds: deletedTopicIds.value,
      });
      if (deleteResult.data.code !== 0) {
        message.error(deleteResult.data.message);
      } else {
        message.success("删除题目成功");
        deletedTopicIds.value = []; // 清空删除队列
      }
    }

    // 处理更新和新增
    const topicsToUpdate = topicDTO.value.filter(
      (topic) => topic.topicId && topic.topicId !== 0
    );
    const topicsToCreate = topicDTO.value.filter(
      (topic) => !topic.topicId || topic.topicId === 0
    );

    if (topicsToUpdate.length > 0) {
      updateResult = await updateTopic({
        appId: props.appId as any,
        topics: topicsToUpdate,
      });
      if (updateResult.data.code !== 0) {
        message.error(updateResult.data.message);
      }
    }

    if (topicsToCreate.length > 0) {
      createResult = await addTopic({
        appId: props.appId as any,
        topics: topicsToCreate,
      });
      if (createResult.data.code !== 0) {
        message.error(createResult.data.message);
      }
    }

    // 如果所有操作成功，跳转
    message.success("操作成功，即将跳转到应用详情页");
    setTimeout(() => {
      router.push(`/app/detail/${props.appId}`);
    }, 800);
  } catch (error) {
    message.error("操作失败，" + (error as Error).message);
  } finally {
    buttonDisable.value = false;
  }
};

/**
 * AI 生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.TopicDTO[]) => {
  message.success(`AI 生成题目成功，生成 ${result.length} 道题目`);
  topicDTO.value = [...topicDTO.value, ...result];
};

/**
 * AI 生成题目成功后执行（SSE）
 */
const onAiGenerateSuccessSSE = (result: API.TopicContent) => {
  // 将返回的 TopicContent 对象包装成一个完整的 TopicDTO 对象
  const newTopic: API.TopicDTO = {
    topicId: 0, // 假设新生成的题目没有 ID
    topicContent: result,
    difficulty: 0, // 默认难度
  };
  // 将新题目添加到 topicDTO 中
  topicDTO.value = [...topicDTO.value, newTopic];
};

/**
 * SSE 开始生成
 * @param event
 */
const onSSEStart = (event: any) => {
  message.success("开始生成");
};

/**
 * SSE 生成完毕
 * @param event
 */
const onSSEClose = (event: any) => {
  message.success("生成完毕");
};
</script>
