// @ts-ignore
/* eslint-disable */
import request from '@/request';

/** 添加题目 POST /topic/add */
export async function addTopic(body: API.TopicAddRequest, options?: { [key: string]: any }) {
  return request<API.BaseResponseBoolean>(`/api/topic/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** AI生成题目 GLM POST /topic/ai */
export async function aiGenerateTopic(
  body: API.AiGenerateTopicRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseListTopicContent>(`/api/topic/ai`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** AI生成题目 GLM-SSE GET /topic/ai/sse */
export async function aiGenerateTopicSse(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.aiGenerateTopicSSEParams,
  options?: { [key: string]: any },
) {
  return request<API.SseEmitter>(`/api/topic/ai/sse`, {
    method: 'GET',
    params: {
      ...params,
      aiGenerateTopicRequest: undefined,
      ...params['aiGenerateTopicRequest'],
    },
    ...(options || {}),
  });
}

/** 删除题目 POST /topic/delete */
export async function deleteTopic(body: API.TopicDeleteRequest, options?: { [key: string]: any }) {
  return request<API.BaseResponseBoolean>(`/api/topic/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据题目 id 获取题目 GET /topic/get */
export async function getTopicById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTopicByIdParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseTopic>(`/api/topic/get`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据题目 id 获取题目VO GET /topic/get/vo */
export async function getTopicVoById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTopicVOByIdParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponseTopicVO>(`/api/topic/get/vo`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 分页获取题目 POST /topic/list/page */
export async function listTopicByPage(
  body: API.TopicQueryRequest,
  options?: { [key: string]: any },
) {
  return request<API.BaseResponsePageTopic>(`/api/topic/list/page`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更新题目 POST /topic/update */
export async function updateTopic(body: API.TopicUpdateRequest, options?: { [key: string]: any }) {
  return request<API.BaseResponseBoolean>(`/api/topic/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
