import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "homePage" }
const _hoisted_2 = { class: "searchBar" }
const _hoisted_3 = {
  key: 0,
  class: "loading-container"
}

import { ref, watchEffect } from "vue";
import AppCard from "@/components/AppCard.vue";
import API from "@/api";
import { listAppVoByPage } from "@/api/appController";
import message from "@arco-design/web-vue/es/message";
import { REVIEW_STATUS_ENUM } from "@/constant/app";

// 初始化搜索条件（不应该被修改）

export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const initSearchParams = {
  current: 1,
  pageSize: 8,
};

const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});
const dataList = ref<API.AppVO[]>([]);
const total = ref<number>(0);
const isLoading = ref<boolean>();

/**
 * 加载数据
 */
const loadData = async () => {
  isLoading.value = true;
  const params = {
    // 只加载过审的应用
    reviewStatus: REVIEW_STATUS_ENUM.PASS,
    ...searchParams.value,
  };
  const res = await listAppVoByPage(params);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input_search, {
        style: { width: '320px' },
        placeholder: "快速发现答题应用",
        "button-text": "搜索",
        size: "large",
        "search-button": ""
      })
    ]),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_list, {
          key: 1,
          class: "list-demo-action-layout",
          "grid-props": { gutter: [20, 20], sm: 24, md: 12, lg: 8, xl: 6 },
          bordered: false,
          data: dataList.value,
          "pagination-props": {
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
          onPageChange: onPageChange
        }, {
          item: _withCtx(({ item }) => [
            _createVNode(AppCard, {
              app: item,
              isLoading: isLoading.value
            }, null, 8, ["app", "isLoading"])
          ]),
          _: 1
        }, 8, ["data", "pagination-props"]))
  ]))
}
}

})