import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref } from "vue";
// @ts-ignore
import QRCode from "qrcode";
import message from "@arco-design/web-vue/es/message";

/**
 * 定义组件属性类型
 */
interface Props {
  // 分享链接
  link: string;
  // 弹窗标题
  title: string;
}

/**
 * 给组件指定初始值
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'ShareModal',
  props: {
    link: { default: () => "" },
    title: { default: () => "分享" }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

// 要展示的图片
const codeImg = ref();

// 是否可见
const visible = ref(false);

// 打开弹窗
const openModal = () => {
  visible.value = true;
};

// 暴露函数给父组件
__expose({
  openModal,
});

// 关闭弹窗
const closeModal = () => {
  visible.value = false;
};

// 二维码生成
QRCode.toDataURL(props.link)
  .then((url: any) => {
    codeImg.value = url;
  })
  .catch((err: any) => {
    console.error(err);
    message.error("生成二维码失败，" + err.message);
  });

return (_ctx: any,_cache: any) => {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: visible.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
    footer: false,
    onCancel: closeModal
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("h4", { style: {"margin-top":"0"} }, "复制分享链接", -1)),
      _createVNode(_component_a_typography_paragraph, { copyable: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.link), 1)
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("h4", null, "手机扫码查看", -1)),
      _createElementVNode("img", {
        src: codeImg.value,
        alt: "二维码"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})