<template>
  <div id="answerResultPage">
    <!-- 加载中提示 -->
    <div v-if="isLoading" class="loading-container">
      加载中，请稍候...
      <icon-loading />
    </div>
    <a-card v-else>
      <a-row style="margin-bottom: 16px">
        <a-col flex="auto" class="content-wrapper">
          <h2>{{ data.resultName }}</h2>
          <p class="result-desc">结果描述：{{ data.resultDesc }}</p>
          <p v-if="data.scoringStrategy !== 1">结果 id：{{ data.resultId }}</p>
          <p v-if="data.scoringStrategy !== 1">
            结果得分：{{ data.resultScore }}
          </p>
          <p>应用 id：{{ data.appId }}</p>
          <p>应用类型：{{ APP_TYPE_MAP[data.appType] }}</p>
          <p>评分策略：{{ APP_SCORING_STRATEGY_MAP[data.scoringStrategy] }}</p>
          <p>
            <a-space>
              答题人：
              <div :style="{ display: 'flex', alignItems: 'center' }">
                <a-avatar
                  :size="30"
                  :image-url="data.userVO?.userAvatar"
                  :style="{ marginRight: '8px' }"
                />
                <a-typography-text
                  >{{ data.userVO?.userName ?? "匿名用户" }}
                </a-typography-text>
              </div>
            </a-space>
          </p>
          <p>答题时间：{{ dayjs().format("YYYY-MM-DD HH:mm:ss") }}</p>
          <a-space size="medium">
            <a-button type="primary" :href="`/answer/do/${data.appId}`"
              >去答题
            </a-button>
          </a-space>
        </a-col>
        <a-col flex="360px" class="content-wrapper">
          <a-image width="100%" :src="data.resultPicture" />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect, withDefaults } from "vue";
import API from "@/api";
import { getUserAnswerVoById } from "@/api/userAnswerController";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/app";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => {
    return "";
  },
});

const data = ref<API.UserAnswerVO>({});
const isLoading = ref<boolean>(true);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) {
    return;
  }
  const res = await getUserAnswerVoById({
    id: props.id as any,
  });
  if (res.data.code === 0) {
    data.value = res.data.data as any;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
</script>

<style scoped>
#answerResultPage {
}

#answerResultPage .content-wrapper > * {
  margin-bottom: 24px;
}

.result-desc {
  width: 800px;
  overflow: visible;
  line-height: 1.6;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  border-radius: 4px;
}
</style>
