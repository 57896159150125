import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "doAnswerPage",
  style: {"width":"800px"}
}
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { style: {"margin-bottom":"16px"} }
const _hoisted_4 = { style: {"margin-top":"24px"} }

import {
  computed,
  reactive,
  ref,
  watchEffect,
} from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import { listTopicByPage } from "@/api/topicController";
import message from "@arco-design/web-vue/es/message";
import { getAppVoById } from "@/api/appController";
import {
  addUserAnswer,
  generateUserAnswerId,
} from "@/api/userAnswerController";

interface Props {
  appId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DoAnswerPage',
  props: {
    appId: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const isLoading = ref(false);

const app = ref<API.AppVO>({});
const topicDTO = ref<API.Topic[]>([]);
const current = ref(1);
const currentTopic = ref<API.TopicContent>({});
const topicOptions = computed(() => {
  return currentTopic.value?.options
    ? currentTopic.value.options.map((option) => {
        return {
          label: `${option.key}. ${option.value}`,
          value: option.key,
        };
      })
    : [];
});
const currentAnswer = ref<API.TopicAnswerDTO>({
  topicId: 0,
  userAnswer: "",
});
const answerList = reactive<API.TopicAnswerDTO[]>([]);
const submitting = ref(false);
const id = ref<number>();

const generateId = async () => {
  const res = await generateUserAnswerId();
  if (res.data.code === 0) {
    id.value = res.data.data as any;
  } else {
    message.error("获取唯一 id 失败，" + res.data.message);
  }
};

watchEffect(() => {
  generateId();
});

/**
 * 加载数据
 */
const loadData = async () => {
  isLoading.value = true;
  if (!props.appId) {
    isLoading.value = false;
    return;
  }

  let res: any = await getAppVoById({ id: props.appId as any });
  if (res.data.code === 0) {
    app.value = res.data.data as any;
  } else {
    message.error("获取应用失败，" + res.data.message);
  }

  res = await listTopicByPage({
    appId: props.appId as any,
    current: 1,
    pageSize: 100,
    sortField: "createTime",
    sortOrder: "descend",
  });

  if (res.data.code === 0 && res.data.data?.records) {
    // 获取题目列表
    topicDTO.value = res.data.data.records || [];

    // 初始化答案列表
    answerList.splice(0); // 清空原有数据
    topicDTO.value.forEach((topic) => {
      answerList.push({
        topicId: topic.topicId || 0,
        userAnswer: "",
      });
    });

    // 如果有题目，设置当前题目
    if (topicDTO.value.length > 0) {
      setCurrentTopic();
    }
  } else {
    message.error("获取题目失败，" + (res.data.message || "未知错误"));
    topicDTO.value = []; // 确保初始化为空数组
  }
  isLoading.value = false;
};

// 设置当前题目的方法
const setCurrentTopic = () => {
  if (topicDTO.value.length > 0 && current.value <= topicDTO.value.length) {
    const topic = topicDTO.value[current.value - 1];

    // 直接解析topicContent字符串为TopicContent类型
    try {
      if (topic.topicContent) {
        currentTopic.value = JSON.parse(topic.topicContent) as API.TopicContent;
      } else {
        currentTopic.value = { title: "", options: [] };
      }
    } catch (e) {
      console.error("解析题目内容失败", e);
      currentTopic.value = { title: "", options: [] };
    }

    // 设置当前答案
    currentAnswer.value = answerList[current.value - 1];
  }
};

// 监听 appId 变化加载数据
watchEffect(() => {
  if (props.appId) {
    loadData();
  }
});

// 监听当前题目索引变化
watchEffect(() => {
  if (topicDTO.value.length > 0) {
    setCurrentTopic();
  }
});

// 单选处理函数
const doRadioChange = (value: string) => {
  if (currentAnswer.value) {
    currentAnswer.value.userAnswer = value;
  }
};

const nextQuestion = () => {
  if (current.value < topicDTO.value.length) {
    current.value += 1;
  }
};

const prevQuestion = () => {
  if (current.value > 1) {
    current.value -= 1;
  }
};

/**
 * 提交答案
 */
const doSubmit = async () => {
  if (!props.appId || answerList.length === 0) {
    message.error("没有可提交的答案");
    return;
  }

  // 检查是否有未回答的题目
  const unansweredIndex = answerList.findIndex((answer) => !answer.userAnswer);
  if (unansweredIndex !== -1) {
    current.value = unansweredIndex + 1;
    message.warning(`第 ${unansweredIndex + 1} 题尚未回答`);
    return;
  }

  submitting.value = true;
  try {
    const res = await addUserAnswer({
      id: id.value as any,
      appId: props.appId as any,
      userAnswer: answerList,
    });

    if (res.data.code === 0 && res.data.data) {
      await router.push(`/answer/result/${res.data.data}`);
    } else {
      message.error("提交答案失败，" + (res.data.message || "未知错误"));
    }
  } catch (error) {
    console.error("提交答案出错", error);
    message.error("提交答案出错，请稍后重试");
  } finally {
    submitting.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_card, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(app.value.appName), 1),
            _createElementVNode("p", null, "描述：" + _toDisplayString(app.value.appDesc), 1),
            _createElementVNode("p", null, "题目数量：" + _toDisplayString(topicDTO.value.length), 1),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(current.value) + "、" + _toDisplayString(currentTopic.value?.title), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_a_radio_group, {
                direction: "vertical",
                modelValue: currentAnswer.value.userAnswer,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentAnswer.value.userAnswer) = $event)),
                options: topicOptions.value,
                onChange: doRadioChange
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_space, { size: "large" }, {
                default: _withCtx(() => [
                  (current.value < topicDTO.value.length)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        circle: "",
                        disabled: !currentAnswer.value.userAnswer,
                        onClick: nextQuestion
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" 下一题 ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  (current.value === topicDTO.value.length)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 1,
                        type: "primary",
                        loading: submitting.value,
                        circle: "",
                        disabled: !currentAnswer.value.userAnswer,
                        onClick: doSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(submitting.value ? "评分中" : "查看结果"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled"]))
                    : _createCommentVNode("", true),
                  (current.value > 1)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 2,
                        circle: "",
                        onClick: prevQuestion
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" 上一题 ")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
  ]))
}
}

})