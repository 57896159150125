<template>
  <div id="DefaultPage" style="text-align: center; font-size: 50px">
    <a-space>网站为云托管弹性部署，可能需要初始化，请稍等片刻后重试</a-space>
    <a-space>(一般2-3分钟)</a-space>
  </div>
  <div id="DefaultPage" style="text-align: center; font-size: 50px">
    <a-space>可以参考 GitHub 中的项目源码和简介</a-space>
  </div>
  <div id="DefaultPage" style="text-align: center; font-size: 50px">
    <a-space><a href="https://github.com/MrZboyan/wisenest" target="_blank">GitHub</a></a-space>
  </div>
</template>

<script setup lang="ts">
</script>
